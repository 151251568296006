import { Component, OnInit } from '@angular/core';
import { compare } from '../../shared/func';
import { Subscription } from 'rxjs';
import { LinkFileListService } from '../../link-consume/services/link-file-list.service';
import { UserService } from '../../core/user.service';
import { BrowserSupportService } from '../../core/browser-support.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BuildTransferItemService } from '../services/build-transfer-item.service';
import { Router } from '@angular/router';
import { TransferQueueType, TransferStatus } from '../transfer.model';
import { BlendEvent } from '../../shared/models';
import { BroadcastService } from '../../shared/services/broadcast.service';
import { FeatureService } from '../../shared/services/feature.service';
import { TransferItemService } from '../services/transfer-item.service';
import { BlendService } from 'src/app/shared/services/blend.service';

@Component({
    selector: 'sync-transfer',
    templateUrl: './transfer.component.html',
})
export class TransferComponent implements OnInit {
    public transferView: sync.ITransferView;
    public isPublic: number;
    private subscription: Subscription;
    private uploadable: boolean;
    public ufile: any;
    public isDisplayRetryButtonEventLogged: boolean;
    private isRetryAllowed: boolean;

    constructor(
        private buildTransferItemService: BuildTransferItemService,
        private linkFileListService: LinkFileListService,
        private userService: UserService,
        private browserSupportService: BrowserSupportService,
        public activeModal: NgbActiveModal,
        private router: Router,
        private broadcastService: BroadcastService,
        private featureService: FeatureService,
        private transferItemService: TransferItemService,
        private blendService: BlendService
    ) {}

    public trackbyFunction(index) {
        return index;
    }

    async ngOnInit() {
        this.transferView = this.buildTransferItemService.view;
        this.router.url.includes('/dl/') ? this.isPublic = 1 : this.isPublic = 0;

        this.subscription = this.linkFileListService
            .getSubscription()
            .subscribe((data) => {
                // do not check path list length as empty folder still needs to be uploadable
                if (data && data.pathlist && data.loaded && data.sorted) {
                    this.uploadable = data.upload > 0;
                }
            });

        this.isRetryAllowed = await this.featureService.isAllowed('retryUploads');
    }

    public testFileUpload(): boolean {
        return this.browserSupportService.testFileUpload();
    }
    public testDirUpload() {
        return this.browserSupportService.testDirUpload();
    }

    public showUploadBtn(): boolean {
        if (this.isPublic) {
            return this.uploadable;
        } else {
            return (
                this.router.url.includes('/files') ||
                this.router.url.includes('/vault')
            );
        }
    }

    public onDisplayRetryBtn(): boolean {
        if (!this.isDisplayRetryButtonEventLogged) {
            for (const transferItem of this.transferView.upload.queue) {
                if (transferItem.status >= 7000
                    && transferItem.status <= 7244
                    && transferItem.type == 20
                    && transferItem.retryCount < 3
                    && this.isRetryAllowed) {
                    this.blendService.track(BlendEvent.RETRY_UPLOAD_BUTTON_DISPLAYED);
                }
            }
            this.isDisplayRetryButtonEventLogged = true;
        }
        return true;
    }

    public clear() {
        this.transferView.download.queue = this.transferView.download.queue.filter(
            (transferItem) =>
                (transferItem.status != 1 && transferItem.status <= 1000) ||
                ![10, 20].includes(transferItem.type)
        );

        this.transferView.upload.queue = this.transferView.upload.queue.filter(
            (transferItem) =>
                (transferItem.status != 1 && transferItem.status <= 1000) ||
                ![10, 20].includes(transferItem.type)
        );
    }

    public downloadCompatible(file: sync.IFile) {
        this.buildTransferItemService
            .getCompatUrl(file, this.buildTransferItemService.ACT_DOWNLOAD)
            .then((data) => (window.location.href = data));
    }

    public sort(a: sync.ITransferItem, b: sync.ITransferItem) {
        return compare(a._initTime, b._initTime, true);
    }

    public uploadFileSelect(event: any) {
        this.ufile = event.target.files;
    }

    public cancelUpload(transferItem: sync.ITransferItem) {
        if (transferItem.status >= 10 && transferItem.status <= 20) {
            transferItem.status = TransferStatus.STATUS_UPLOAD_CANCELLED;
            this.transferView.upload.isProcessing = false;
            if (this.transferView.upload.queue.length > 0) {
                this.buildTransferItemService.runQueue(TransferQueueType.UPLOAD);
            }
        } else {
            transferItem.status = TransferStatus.STATUS_UPLOAD_CANCELLED;
        }
    }

    public async retryUpload(transferItem: sync.ITransferItemUpload) {
        if (transferItem.status >= 7000 && transferItem.status <= 7244 && transferItem.retryCount < 3 && this.isRetryAllowed) {
            ++transferItem.retryCount;
            transferItem.chunkqueue = this.transferItemService.getChunkQueue(transferItem);
            transferItem.bytes_sent = 0;
            transferItem.progress_percent = 0;
            transferItem.progress_network = 0;
            transferItem.progress_crypt = 0;
            transferItem.status = TransferStatus.STATUS_WAITING;
            await this.buildTransferItemService.runQueue(TransferQueueType.UPLOAD);
            this.blendService.track(BlendEvent.BUTTON_CLICKED, {button_text: 'Retry upload'});
        }
    }
}

<div class="modal-header">
    <button type="button" class="close" id="close-manage-link" (click)="closeDialog()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title ellipsis-text">
        <span>Manage link - {{ link.name }}</span>
    </h4>
</div>
<form autocomplete="off" (submit)="linkSettingsForm.valid && onSubmit()" [formGroup]="linkSettingsForm" method="POST"
    ngNativeValidate>
    <div class="modal-body">
        <sync-error [errcode]="errcode"></sync-error>
        <div class="error-general-overlay error-general-overlay-inline error-push-bottom" *ngIf="link.is_expired">
            <i class="fa fa-exclamation-triangle"></i>
            This link has expired and is no longer viewable.
        </div>
        
        <div class="error-general-overlay error-general-overlay-inline error-push-bottom" *ngIf="!this.isEnced">
            <i class="fa fa-spin fa-spinner"></i>
            {{ modalText }}
        </div>
    
        <div
            class="error-general-overlay error-general-overlay-inline error-push-bottom"
            role="alert"
            *ngIf="link.is_download_limit"
        >
            <i class="fa fa-exclamation-triangle"></i>
            This link has reached it's download limit and is no longer viewable.
        </div>

        <div class="error-general-overlay error-general-overlay-inline error-push-bottom" *ngIf="!this.isEnced">
            <i class="fa fa-spin fa-spinner"></i>
            Encrypting link, Please wait...
        </div>

        <div class="error-general-overlay error-general-overlay-inline error-push-bottom" role="alert"
            *ngIf="link.is_download_limit">
            <i class="fa fa-exclamation-triangle"></i>
            This link has reached it's download limit and is no longer viewable.
        </div>

        <!-- link summary visible by default -->
        <div class="row" *ngIf="this.isEnced">
            <!-- VIEW 1 -->
            <div class="col-sm-12">
                <label class="" for="">Link:</label>
                <input type="text" class="form-control" [value]="link.linkUrl" readonly id="link-url" />

                <div class="link-settings-buttons">
                    <button *ngIf="link.compat && (isPro && !isOnTrial) && this.isEnced" type="button" (click)="setState(5)" class="btn btn-default btn-sm" id="copy-link-btn">
                        Email link
                    </button>
                    <button type="button" (click)="copy('link-url')" class="btn btn-default btn-sm pull-right"
                        id="copy-link-btn">
                        {{ copyText }}
                    </button>
                    <a class="btn btn-default btn-sm pull-right" style="margin-right: 10px;" id="open-link-btn"
                        *ngIf="link.togglePassword" [href]="link.linkUrl" target="_blank">Open link</a>
                </div>
                <hr />
            </div>
        </div>
        <div *ngIf="this.isEnced">
            <div class="row">
                <div class="col-sm-12">
                    <b>Settings</b>
                    <a (click)="setState(2)" class="pull-right" style="cursor: pointer;" id="link-settings-path-btn">
                        View advanced settings
                    </a>
                </div>
            </div>
            <br />
            <div class="row">
                <div class="col-sm-4">
                    Link password:
                    <br *ngIf="linkEnforcedSettings.isLinkPasswordRequired" />
                    <i *ngIf="linkEnforcedSettings.isLinkPasswordRequired">Required</i>
                </div>
                <div class="col-sm-8 cover">
                    <input type="password" id="link-precreate-settings-password" placeholder="Enter password"
                        class="form-control" formControlName="password" syncShowHidePassword identity="linkpass" />
                </div>
            </div>
            <div class="row" *ngIf="isPro">
                <hr />
                <div class="col-sm-4">
                    Expiry date:
                    <br *ngIf="linkEnforcedSettings.isLinkExpiryDateRequired" />
                    <i *ngIf="linkEnforcedSettings.isLinkExpiryDateRequired">Required</i>
                </div>
                <div class="col-sm-4">
                    <div class="input-group">
                        <input type="text" name="expiryDate" id="link-settings-expiry" ngbDatepicker
                            placeholder="No expiry date" class="form-control" formControlName="expiryDate"
                            autocomplete="off" [minDate]="expDateMin" [startDate]="expDateMin" #d="ngbDatepicker"
                            (dateSelect)="onDateSelection()" />
                        <span class="input-group-btn">
                            <button type="button" class="btn btn-default" (click)="d.toggle()">
                                <i class="fa fa-calendar"></i>
                            </button>
                        </span>
                    </div>
                    <a *ngIf="linkSettingsForm.get('expiryDate').value" class="showhand" (click)="clearExp()">Clear
                        expiry</a>
                </div>
                <div class="col-sm-4">
                    <ngb-timepicker formControlName="expiryTime" [meridian]="true" [spinners]="false"></ngb-timepicker>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button type="submit" class="btn btn-primary" id="save-settings-dialog-btn"
            [disabled]="spinner || !linkSettingsForm.valid">
            <i class="fa fa-spin fa-spinner" *ngIf="spinner"></i>
            Save settings
        </button>
        <button type="button" class="btn btn-default" id="close-manage-link" (click)="close.emit(false)">
            Close
        </button>
    </div>
</form>